<template>
  <div class="hasValidatedMessage">
    <i class="gavc-icon gavc-icon-check mr-2"></i> {{text}}
  </div>
</template>

<script>
export default {
  name: 'hasValidatedMessage',
  props: {
    text: {
      type: String,
      default: ""
    }
  },

}
</script>

<style scoped>
.hasValidatedMessage {
  background-color: #28a745;
  border-radius: 20px;
  color: white;
  margin: auto;
  padding: 20px;
  text-align: center;
  width: 75%;
}
</style>
